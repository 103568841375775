<template>
    <div class="fullscreenwidth">
        <div style="display:flex;flex-direction:column;">
            <div>
                <b-card>
                    <page-title v-bind:heading=heading v-bind:subheading=subheading :icon=icon></page-title>

                    <b-table responsive="sm" :items="[vacation]"
                    :fields="fields"></b-table>
                    <p style="font-weight: 800;">
                        Інформація на ПЕРШЕ число поточного місяця.
                    </p>
                </b-card>
                <b-card style="margin-top:5px;">
                    <page-title v-bind:heading="heading_otgul" :icon="icon_otgul"></page-title>
                    <b-table responsive="sm" :items="otguls"
                    :fields="fieldsOtgul"></b-table>

                    <p style="font-weight: 800;">
                        Інформація на ПЕРШЕ число поточного місяця.
                    </p>
                </b-card>
            </div>
            <b-card v-if="myWorkers.length > 0" style="margin-top:5px; margin-bottom: 5px;">
                <page-title v-bind:heading="heading_workers" :icon="icon_workers"></page-title>
                <div >
                    <button v-if="vacationsPage" class="btn btn-dark">Відпустки</button>
                    <button v-else class="btn" v-on:click="GoToVacations()">Відпустки</button>
                    <button v-if="workerPayedOtgul" class="btn btn-dark">Відгули за рахунок компанії</button>
                    <button v-else class="btn" v-on:click="GoTopayedOtguls()">Відгули за рахунок компанії</button>
                    <button v-if="!workerPayedOtgul&&!vacationsPage" class="btn btn-dark" v-on:click="GoTonotPayedOtguls()">Відгули за свій рахунок </button>
                    <button v-else class="btn" v-on:click="GoTonotPayedOtguls()">Відгули за свій рахунок </button>

                    <b-table v-if="workerPayedOtgul" responsive="sm" :items="workersPayed"
                    :fields="fieldsWorkersOtgul"></b-table>
                    <b-table v-if="workerPayedOtgul===false&&vacationsPage===false" responsive="sm" :items="workersNotPayed"
                    :fields="fieldsWorkersOtgul"></b-table>
                    <b-table v-if="vacationsPage" responsive="sm" :items="vacationList"
                    :fields="fieldsWorkers"></b-table>
                </div>
                    <p style="font-weight: 800;">
                        Інформація на ПЕРШЕ число поточного місяця.
                    </p>
            </b-card>
        </div>
    </div>

</template>

<script>
    import { mapGetters } from "vuex";
    import PageTitle from "../Layout/Components/PageTitle.vue";
    //import VuePerfectScrollbar from 'vue-perfect-scrollbar'

    //import chart1 from './Analytics/chart1.js';
    // import chart2 from './Analytics/chart2';
    // import chart3 from './Analytics/chart3';

    import {library} from '@fortawesome/fontawesome-svg-core'
    import {
        faTrashAlt,
        faCheck,
        faCalendarAlt,
        faAngleDown,
        faAngleUp,
        faTh,
    } from '@fortawesome/free-solid-svg-icons'
    //import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

    library.add(
        faTrashAlt,
        faCheck,
        faAngleDown,
        faAngleUp,
        faTh,
        faCalendarAlt,
    );

    export default {
        components: {
            PageTitle,
            //VuePerfectScrollbar,
            //'font-awesome-icon': FontAwesomeIcon,
            //chart1,
            // chart2,
            // chart3,

        },
        data: () => ({
            heading: 'Панель відпусток',
            subheading: 'Гарної відпустки і приємних вражень.',
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
            icon_otgul: 'pe-7s-joy icon-gradient bg-tempting-azure',
            heading_otgul: 'Панель відгулів',
            icon_workers: 'pe-7s-users icon-gradient bg-tempting-azure',
            heading_workers: 'Відгули і відпустки моїх підлеглих',
            otguls:[
            ],
            workersPayed:[],
            workersNotPayed:[],
            myWorkers:[],
            workerPayedOtgul: false,
            vacationsPage: true,
            vacationList:[],
            fieldsWorkers:[
                {
                    key:'name',
                    label:'Имя'
                },
                {
                    key: 'startDate',
                    label: 'Початок відліку'
                },
                {
                    key: 'endDate',
                    label: 'Кінець відліку'
                },
                {
                    key: 'daysTotal',
                    label: 'Всього'
                },
                {
                    key: 'daysUsed',
                    label: 'Використано'
                },
                {
                    key: 'daysLeft',
                    label: 'Залишилось днів відпустки'
                },
            ],
            fieldsWorkersOtgul:[
                {
                    key:'name',
                    label:'Имя'
                },
                {
                    key: 'startDate',
                    label: 'Початок відліку'
                },
                {
                    key: 'endDate',
                    label: 'Кінець відліку'
                },
                {
                    key: 'daysTotal',
                    label: 'Всього'
                },
                {
                    key: 'daysUsed',
                    label: 'Використано'
                },
                {
                    key: 'daysLeft',
                    label: 'Залишилось днів відгулів'
                },
            ],
            fields:[
                {
                    key: 'startDate',
                    label: 'Початок відліку'
                },
                {
                    key: 'endDate',
                    label: 'Кінець відліку'
                },
                {
                    key: 'daysTotal',
                    label: 'Всього'
                },
                {
                    key: 'daysUsed',
                    label: 'Використано'
                },
                {
                    key: 'daysLeft',
                    label: 'Залишилось днів відпустки'
                },
            ],
            fieldsOtgul:[
                {
                    key: 'type',
                    label: ''
                },
                {
                    key: 'startDate',
                    label: 'Початок відліку'
                },
                {
                    key: 'endDate',
                    label: 'Кінець відліку'
                },
                {
                    key: 'daysTotal',
                    label: 'Всього'
                },
                {
                    key: 'daysUsed',
                    label: 'Використано'
                },
                {
                    key: 'daysLeft',
                    label: 'Залишилось днів відгулів'
                },
            ]
        }),
        computed: {
            ...mapGetters({ 
                vacation: "employeeVacation" ,
            }),
        },
        methods: {
            GoToVacations(){
                this.workerPayedOtgul = false;
                this.vacationsPage = true;
            },
            GoTopayedOtguls(){
                this.workerPayedOtgul = true;
                this.vacationsPage = false;
            },
            GoTonotPayedOtguls(){
                this.workerPayedOtgul = false;
                this.vacationsPage = false;
            },
            async getMyOtgul(){
                //console.log(this.$store.getters.thatAdminEmail);
                var otguls = await fetch('https://vacation-inch-backend.azurewebsites.net/api/otgul/getmyotguls?email='+this.$store.getters.thatAdminEmail);
                var myotguls =  await otguls.json();
                console.log(myotguls);
                if(myotguls.otgul){
                    myotguls.otgul.type = "За рахунок компанії";
                    this.otguls.push({type: myotguls.otgul.type, startDate: myotguls.otgul.startDate, endDate: myotguls.otgul.endDate, daysTotal: myotguls.otgul.daysTotal, daysUsed: myotguls.otgul.daysUsed, daysLeft: myotguls.otgul.daysLeft});
                }
                if(myotguls.notPayedOtgul){
                    myotguls.notPayedOtgul.type = "За свій рахунок";
                    this.otguls.push(myotguls.notPayedOtgul);
                }
                //console.log(myotguls);
            },
            async getToken(){
                var result = await fetch('https://vacation-inch-backend.azurewebsites.net/api/otgul/getmegraph',{method:'POST'});
                var resultR = await result.json();
                await this.getUsers(resultR.access_token);
                //console.log(resultR.access_token);
            },
            async getGraphVia(token, NeededEmail){
                try{
                    if(NeededEmail === "Vadik-klo4ko@ukr.net"){
                        return null;
                    }
                    var result = await fetch('https://graph.microsoft.com/v1.0/users/'+NeededEmail + '?$expand=manager($select=displayName,mail)&$select=displayName,mail',{
                        method: 'GET',
                        headers: {
                            'Authorization': 'Bearer '+ token
                        }
                    });
                    var res =  await result.json();
                    console.log(res);
                    return res;
                }catch{
                    console.log();
                }
                return null;

                
            },
            async getUsers(access_token){
                //console.log(access_token);
                var allUsers = await fetch('https://vacation-inch-backend.azurewebsites.net/api/otgul/getotguls', {method:'GET', headers:{'Content-Type':'application/json'}});
                var allUsersRes = await allUsers.json();
                var allUsersEmails = allUsersRes.otgul;
                var emails = []
                for(let x in allUsersEmails)
                    emails.push(allUsersEmails[x].email);
                console.log(emails);
                var index = emails.indexOf("Vadik-klo4ko@ukr.net");
                if (index !== -1) {
                    emails.splice(index, 1);
                }

                var users = [];
                var result = await fetch('https://vacation-inch-backend.azurewebsites.net/api/otgul/getme', {method:'POST',headers:{'Content-Type':'application/json'}, body:JSON.stringify(access_token)});
                var resultR = await result.json();
                console.log("Result r length = ");
                console.log(resultR.value.length);
                for(var i = 0; i< resultR.value.length; i++)
                {
                    var index = emails.indexOf(resultR.value[i].mail);
                    if (index !== -1) {
                        emails.splice(index, 1);
                    }
                    try{
                        if(resultR.value[i].manager.mail === this.$store.getters.thatAdminEmail){
                            users.push({ id: i, email:  resultR.value[i].mail});

                        } else if(this.$store.getters.thatAdminEmail === "g.spivak@inchkiev.ua" && resultR.value[i].manager.mail === "a.stankevich@inchkiev.ua"){
                            users.push({ id: i, email:  resultR.value[i].mail});
                        }
                    }
                    catch{
                        console.log();
                    }
                }
                console.log(emails);
                for(let j = users.length, m = 0; j < emails.length + users.length; j++, m++){
                    console.log("started")
                    try{
                        if(emails[m] === undefined){
                            console.log("undefined");
                            continue;
                        }
                        let us = await this.getGraphVia(access_token, emails[m]);
                        if( us === null){
                            console.log("303")
                            continue;
                        }
                        if(us.manager.mail === this.$store.getters.thatAdminEmail){
                            console.log("done")

                            users.push({ id: j, email:  us.mail});

                        } else if(this.$store.getters.thatAdminEmail === "g.spivak@inchkiev.ua" && us.manager.mail === "a.stankevich@inchkiev.ua"){
                            console.log("smth weird")

                            users.push({ id: j, email:  us.mail});
                        }
                    }catch{
                        console.log("catched")

                        continue;
                    }
                }
                console.log(users);
                this.myWorkers = users;
                var getOtguls = await fetch('https://vacation-inch-backend.azurewebsites.net/api/otgul/getotguls', {method:'POST', headers:{'Content-Type':'application/json'}, body: JSON.stringify(users)});
                var getOtgulsR = await getOtguls.json();
                this.workersPayed = getOtgulsR.otgul;
                this.workersNotPayed = getOtgulsR.notPayedOtgul;
                this.vacationList = getOtgulsR.workerVacations;
                console.log(getOtgulsR);
            }
        },
        mounted(){
            //console.log(this.vacation);
            this.getMyOtgul();
            this.getToken();
        }
        
    }
</script>
<style scoped lang="scss">
.fullscreenwidth{
    width:100%;
}
</style>
